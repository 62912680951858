<template>
  <HeaderFullpage
    v-if="step <= (isShowShedule ? 7 : 6)"
    :title="t('companyCreate.title')"
    has-custom-navigation
    @back="emit('back')"
  />

  <div class="registration-wrapper">
    <MainInfo v-if="step === 1" v-model="companyInfo" :errors="commonErrors.mainInfoStep" />
    <CompanyType v-if="step === 2" v-model="companyInfo" :errors="commonErrors.typeStep" />
    <AddressStep v-if="step === 3" v-model="companyInfo" :errors="commonErrors.addressStep" />
    <AnimalStep v-if="step === 4" v-model="companyInfo" :errors="commonErrors.animalStep" />
    <ContactInfo v-if="step === 5" v-model="companyInfo" :errors="commonErrors.contactsStep" />
    <WorkingHours v-if="step === 6 && isShowShedule" v-model="companyInfo" :errors="commonErrors.hoursStep" />
    <AdditionalInfo v-if="step === (isShowShedule ? 7 : 6)" v-model="companyInfo" :errors="commonErrors.infoStep" />
    <SuccessStep v-if="step === (isShowShedule ? 8 : 7)" @click="emit('final')" />

    <div style="flex: 1" />

    <Actions
      v-if="step <= (isShowShedule ? 7 : 6)"
      :index="step"
      :max-index="steps"
      :is-last-step="step === steps"
      :final-button-text="finalButtonText"
      @back="emit('back')"
      @next="emit('next')"
      @create="emit('create')"
    />
  </div>
</template>

<script setup>
import { HeaderFullpage } from '@/UI';

import MainInfo from './steps/MainInfo.vue';
import CompanyType from './steps/CompanyType.vue';
import AddressStep from './steps/AddressStep.vue';
import AnimalStep from './steps/AnimalStep.vue';
import ContactInfo from './steps/ContactInfo.vue';
import WorkingHours from './steps/WorkingHours.vue';
import AdditionalInfo from './steps/AdditionalInfo.vue';
import SuccessStep from './steps/SuccessStep.vue';

import Actions from './components/Actions.vue';
import { computed } from 'vue';
const emit = defineEmits(['back', 'next', 'create', 'final'])

const step = defineModel('step', { type: Number, default: 1 })
const companyInfo = defineModel('companyInfo', { type: Object, default: () => {} })
const props = defineProps({
  commonErrors: { type: Object, default: () => {} },
  isShowShedule: { type: Boolean, default: false },
  finalButtonText: { type: String, default: 'Создать компанию' },
})

const steps = computed(() => props.isShowShedule ? 7 : 6);
</script>

<style scoped lang="scss">
.registration-wrapper {
  padding: 40px 20px 20px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
