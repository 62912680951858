<template>
  <BlockHeader
    :title="t('companyCreate.contactsStep.title')"
    :text="t('companyCreate.contactsStep.description')"
  />

  <div style="display: flex; flex-direction: column; gap: 10px">
    <InputBasePhone
      v-model="companyInfo.tel"
      :label="t('account.placeholders.phone')"
      :error="errors?.tel"
    />
    <InputBase
      v-model="companyInfo.email"
      :label="t('account.placeholders.mail')"
      :error="errors?.email"
    />
    <InputBase
      v-model="companyInfo.telegram"
      :label="t('account.placeholders.tg')"
      :error="errors?.telegram"
    />
    <InputBasePhone
      v-model="companyInfo.whatsApp"
      :label="t('account.placeholders.wa')"
      :error="errors?.whatsApp"
    />
    <InputBaseWebsite
      v-model="companyInfo.web"
      :label="t('account.placeholders.site')"
      :error="errors?.web"
    />
  </div>
</template>

<script setup>
import BlockHeader from '../components/BlockHeader.vue';
import {
  InputBase,
  InputBasePhone,
  InputBaseWebsite,
} from '@/UI'

const companyInfo = defineModel({ type: Object, default: () => {} })
defineProps({
  errors: {
    type: Object,
    default: () => {},
  }
})
</script>

<style scoped lang="scss">
</style>
