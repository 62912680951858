<template>
  <div class="success-wrapper">
    <div class="block-title">
      {{ t('companyCreate.success.title') }}
    </div>
    <div class="block-text">
      {{ t('companyCreate.success.description') }}
    </div>
    <ButtonBase variant="light" small width-auto>
      {{ t('accounts.title') }}
    </ButtonBase>

    <img :src="imagePath" alt="" class="happy-animal-image" @click="onSuccess">
  </div>
</template>

<script setup>
import { ButtonBase } from '@/UI';
import { computed } from 'vue';

const imagePath = computed(() => new URL(`../../../assets/images/happy-animal.svg`, import.meta.url).href)

const emit = defineEmits(['success']);
const onSuccess = () => {
  emit('success')
}
</script>

<style scoped lang="scss">
.success-wrapper {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.block-title {
  font-size: 24px;
  line-height: 26px;
  color: #000;
  @include font-semibold;
}
.block-text {
  font-size: 16px;
  line-height: 21.8px;
  color: #5B5F7C;
  @include font-semibold;
  text-align: center;
}
.happy-animal-image {
  position: absolute;
  bottom: 0;
  width: 316px;
  left: calc(50% - 316px/2);
}
</style>
