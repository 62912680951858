<template>
  <HeaderFullpage :title="t('accounts.title')" />
  <PageLoader v-if="isLoading" />
  <LayoutBase v-else style="margin-bottom: 80px;" background-color="#F9FAFD">
    <div v-if="!companiesList.length && !isLoading" class="info">
      {{ t('accounts.null') }}
    </div>
    <div v-if="isLoading" class="info">
      {{ t('common.loading') }}
    </div>

    <AddEntity to="/company-create" />
    <!-- <AddEntity to="/account-create" temp /> -->

    <div style="display: grid; gap: 10px;">
      <EntityItem
        v-for="item in companiesList"
        :key="item.id"
        :item="item"
        :to="`/account/${item.id}?isMy`"
        @show-context="openContext({ id: item.id, hasShowcase: item.hasShowcase })"
      >
        <template #description>
          {{ item.isCurrentUserAnOwner ? t('accounts.isCompanyOwner') : '-' }}
        </template>
      </EntityItem>
    </div>
  </LayoutBase>

  <ContextPane
    ref="ContextPaneREF"
    :actions="actions"
    @on-delegate="onDelegate"
    @on-fork="onFork"
    @on-edit="onEdit"
    @on-delete="onDelete"
    @add-showcase="onAddShowcase"
    @edit-showcase="onAddShowcase"
  />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { HeaderFullpage } from '@/UI'
import { LayoutBase } from '@/components/Layouts'
import { ContextPane } from '@/components/ContextPane'
import AddEntity from '@/components/AddEntity.vue';
import EntityItem from '@/components/EntityItem.vue';
import { useRouter } from 'vue-router'
import { getCompaniesList, deleteCompany } from '@/app/api/company'
import { useConfirm } from '@/composables/useConfirm';
import { successToast, errorToast } from '@/helpers/showToast'

import { PageLoader } from '@/components/PageLoader'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()

const isLoading = ref(true)
const companiesList = ref([])
const ContextPaneREF = ref()

const init = async () => {

  const result = await getCompaniesList();
  companiesList.value = result;
  isLoading.value = false;
}

const onEdit = (id) => {
  router.push(`/company-edit/${id}`)
}

const onFork = (id) => {
  router.push(`/account-create?fork=${id}`)
}

const { onConfirm } = useConfirm();
const onDelete = async (id) => {
  const response = await onConfirm({ title: t('accounts.toast.confirmToDelete') })
  if (response) {
    const { success } = await deleteCompany({ id })

    if (!success) {
      errorToast({ description: t('accounts.toast.deleteFail') })
      return
    }
    if (success) {
      successToast({ description: t('accounts.toast.deleteSuccess') })
      companiesList.value = companiesList.value.filter(el => el.id !== id)

    }
  }
}

const onDelegate =(id)=>{
  router.push(`/delegate?id=${id}`)
}
const onAddShowcase = (id) => {
  router.push(`/account-showcases/${id}`)
}

const doesAccountHasShowcase = ref(false);
const openContext = ({ id, hasShowcase }) => {
  doesAccountHasShowcase.value = hasShowcase;
  ContextPaneREF.value.open({ id })
}

const actions = computed(() => {
  if (doesAccountHasShowcase.value) {
    return ['edit', 'edit-showcase', 'delegate', 'fork', 'delete']
  }

  return ['edit', 'add-showcase', 'delegate', 'fork', 'delete']
})

onMounted(() => {
  init()
})
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.info {
  color: #C6C8DA;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
</style>
