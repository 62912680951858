<template>
  <div class="block-item">
    <div class="block-title">
      <slot name="title" />
    </div>
    <div class="block-description">
      <slot name="description" />
    </div>
    <slot />
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.block-item {
  background-color: white;
  border: 1px solid #E8EAFC;
  padding: 20px;
  min-height: 120px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}

.block-title {
  font-size: 16px;
  line-height: 14px;
  color: #000;
  @include font-bold;
}

.block-description {
  font-size: 14px;
  line-height: 19px;
  color: #5B5F7C;
  @include font-semibold;
}
</style>
