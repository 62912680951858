<template>
  <div class="steps">
    {{ index }} / {{ maxIndex }}
  </div>
  <div class="actions">
    <ButtonBase variant="bordered" style="background-color: #ffffff;" @click="emit('back')">
      {{ t('common.back') }}
    </ButtonBase>
    <ButtonBase v-if="isLastStep" @click="emit('create')">
      {{ finalButtonText }}
    </ButtonBase>
    <ButtonBase v-else @click="emit('next')">
      {{ t('common.continue') }}
    </ButtonBase>
  </div>
</template>

<script setup>
import { ButtonBase } from '@/UI'
defineProps({
  index: { type: Number, default: 0 },
  maxIndex: { type: Number, default: 0 },
  isLastStep: { type: Boolean, default: false },
  finalButtonText: { type: String, default: 'Создать компанию' },
})

const emit = defineEmits(['back', 'next', 'create']);
</script>

<style scoped lang="scss">

.steps {
  color: #8E92AF;
  font-size: 20px;
  @include font-light;
  text-align: center;
  margin: 40px 0 20px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
