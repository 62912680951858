<template>
  <BlockHeader
    :title="t('companyCreate.mainInfoStep.title')"
    :text="t('companyCreate.mainInfoStep.description')"
  />
  <InputBase
    v-model="companyInfo.name"
    :label="t('common.companyName')"
    :error="errors?.name"
  />
  <div style="margin-bottom: 20px;" />
  <!-- TODO create file uploader component -->
  <div class="flex-center" style="flex-direction: column;">
    <template v-if="typeof companyInfo.logo === 'string' && companyInfo.logo.startsWith('http')">
      <img
        class="logo-image"
        :src="getPhotoURL(companyInfo.logo)"
        @click="onContextOpen"
      >
    </template>
    <template v-else>
      <div
        v-for="(photo, index) in companyInfo.logo"
        :key="index"
      >
        <img
          class="logo-image"
          :src="getPhotoURL(photo)"
          @click="onContextOpen"
        >
      </div>
    </template>

    <ButtonBase variant="light" small width-auto @click="onContextOpen">
      {{ t('companyCreate.mainInfoStep.uploadLogo') }}
    </ButtonBase>
    <ErrorText v-if="isFileUploaderErrorVisible && errors?.logo && errors?.logo[0]" :text="errors?.logo[0].message" />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useFileDialog } from '@vueuse/core';

import BlockHeader from '../components/BlockHeader.vue';

import { InputBase, ButtonBase, ErrorText } from '@/UI';

const companyInfo = defineModel({ type: Object, default: () => {} })

const { open: onContextOpen, onChange, files } = useFileDialog({
  multiple: false,
  reset: false,
  accept: import.meta.env.VITE_ACCEPT_PHOTO
})

const isFileUploaderErrorVisible = ref(false);
onChange(() => {
  companyInfo.value.logo = files.value
  isFileUploaderErrorVisible.value = false;
})

const props = defineProps({
  errors: {
    type: Object,
    default: () => {},
  }
})

watch(computed(() => props.errors?.logo && props.errors?.logo[0]), (value) => {
  if (value) {
    isFileUploaderErrorVisible.value = true;
  }
})

const getPhotoURL = (photo) => {
  console.log(photo);
  const photoLink =
    photo?.path ||
    (String(photo)?.includes('https://img') ? photo : URL.createObjectURL(photo))

  return photoLink
}

</script>

<style scoped lang="scss">
.logo-image {
  width: 150px;
  height: auto;
  border-radius: 20px;
  border: 1px solid #E8EAFC;

  margin-bottom: 20px;
}
</style>
