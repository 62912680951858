<template>
  <template v-if="step">
    <CompanyTemplate
      v-model:step="step"
      v-model:company-info="companyInfo"
      :is-show-shedule="isShowShedule"
      :common-errors="commonErrors"
      final-button-text="Сохранить"
      @back="onBack"
      @next="onNext"
      @create="onCompanyEdit"
      @final="onFinalStep"
    />
  </template>
  <template v-else>
    <HeaderFullpage :title="t('accounts.accountEdit')" has-custom-navigation @back="onRouteBack" />
    <PageLoader v-if="isLoading" />
    <div v-else class="page-wrapper">
      <BlockItem>
        <template #title>
          Ваша компания
        </template>
        <template #description>
          {{ t('companyCreate.mainInfoStep.description') }}
        </template>
        <InputBase
          v-model="companyInfo.name"
          :label="t('common.companyName')"
          readonly
        />
        <div style="display: flex; gap: 10px;">
          <img
            class="logo-image"
            :src="companyInfo.logo"
          >
          <ButtonBase variant="light" xsmall width-auto @click="step = 1">
            Редактировать
          </ButtonBase>
        </div>
      </BlockItem>
      <BlockItem>
        <template #title>
          Информация о компании
        </template>
        <template #description>
          Заполните информацию, которая будет показана на странице вашей компании
        </template>
        <ButtonBase variant="light" xsmall width-auto @click="step = 2">
          Редактировать
        </ButtonBase>
      </BlockItem>
      <BlockItem>
        <template #title>
          {{ t('showcase.title') }}
        </template>
        <template #description>
          Раздел на странице компании и страница в маркетплейсе с вашими товарами
        </template>
        <ButtonBase variant="light" xsmall width-auto @click="onOpenShowcase">
          Открыть
        </ButtonBase>
      </BlockItem>
      <BlockItem>
        <template #title>
          Делегирование и доступы
        </template>
        <template #description>
          Вы можете делигировать компанию под управление другому аккаунту.
        </template>
        <ButtonBase variant="light" xsmall width-auto @click="onDelegate">
          Делегировать
        </ButtonBase>
      </BlockItem>
    </div>
  </template>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import {
  HeaderFullpage,
  ButtonBase,
  InputBase
} from '@/UI'
import { PageLoader } from '@/components/PageLoader'
import { useNavigation } from '@/composables/useNavigation'
import { useConfirm } from '@/composables/useConfirm'
import { uploadPhoto } from '@/states/api'

import { getAccount } from '../AccountView/api'
import { editCompany } from './api'

import { CompanyTemplate, useCompanyValidation } from '@/screens/CompanyTemplate';

import BlockItem from './BlockItem.vue'
import router from '@/router'
import { errorToast, successToast } from '@/helpers/showToast'

const { t } = useI18n()

const isLoading = ref(false)

const { goBack } = useNavigation();
const { onConfirm } = useConfirm();
const onRouteBack = async (event) => {
  event.preventDefault();
  const title = t('account.toast.stopEdit');
  const response = await onConfirm({ title })
  if (response) {
    goBack();
  }
}

const route = useRoute()
const companyInfo = ref({})
const companyId = computed(() => route.params.id)
const init = async () => {
  isLoading.value = true
  const { accounts } = await getAccount({ id: companyId.value })
  companyInfo.value = accounts[0];
  isLoading.value = false
}

init()

const step = ref(null);
const isShowShedule = computed(() => {
  if (companyInfo.value.categoryId === 8 && companyInfo.value.executor_type === 1) {
    return false
  }
  if (companyInfo.value.categoryId === 13) {
    return false
  }
  return true
})

const {
  commonErrors,
  validateFirstStep,
  validateAddressStep,
  validateAnimalStep,
  validateContactStep,
} = useCompanyValidation({ companyInfo })

const onBack = () => {
  if (step.value === 1) {
    step.value = null;
    return;
  }

  if (step.value > 1) {
    step.value -= 1;
  }
}

const scrollToTop = () => {
  try {
    const PANE = document.querySelector('.cupertino.cupertino-fullpage')
    PANE.scrollTo({ top: 0 })
  } catch(e) {
    console.error(e);
  }
}

const processNextStep = (isSuccess = true) => {
  if (isSuccess) {
    step.value += 1
  }
  scrollToTop();
}
const onNext = async () => {
  if (step.value === 1) {
    validateFirstStep().then(processNextStep)
  } else if (step.value === 2) {
    processNextStep()
  } else if (step.value === 3) {
    validateAddressStep().then(processNextStep)
  } else if (step.value === 4) {
    validateAnimalStep().then(processNextStep)
  } else if (step.value === 5) {
    validateContactStep().then(processNextStep)
  } else {
    processNextStep()
  }
}

const onOpenShowcase = () => {
  router.push(`/account-showcases/${companyId.value}`)
}

const onUploadPhoto = async(field) => {
  const { paths } = await uploadPhoto({ photos: companyInfo[field] })
  if (paths) {
    companyInfo[field] = paths
  }
}
const onCompanyEdit = async () => {
  isLoading.value = true

  if (typeof companyInfo.value.logo === 'string' && companyInfo.value.logo.startsWith('http')) {

  } else {
    const { paths } = await uploadPhoto({ photos: Array.from(companyInfo.value.logo) })
    companyInfo.value.logo = paths[0].path

    await onUploadPhoto('photos')
  }
  // await onUploadPhoto('licensePhotos')

  const { success } = await editCompany({
    id: companyId.value,
    account_type_id: companyInfo.value?.account_type_id,
    payload: companyInfo.value,
  })

  if (success) {
    successToast({ description: t('account.toast.editSuccess') })
    router.back()
  } else {
    errorToast({ description: t('toast.somethingWrong') })
  }
}

const onDelegate = () => {
  router.push(`/delegate?id=${companyId.value}`);
}
</script>

<style scoped lang="scss">
.page-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.logo-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 20px;
}

:deep(.input__wrapper) {
  width: 100%;
}
</style>
