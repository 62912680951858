<template>
  <PanePromise
    id="confirm"
    ref="PanePromiseREF"
    fit-content
    @close="no"
  >
    <LayoutBase gap="30">
      <div class="title">
        {{ options?.title || 'Вы уверены что хотите это сделать?' }}
      </div>
      <div class="btns">
        <ButtonBase
          danger
          v-bind="options?.yesButton"
          @click="yes"
        >
          {{ options?.yesButton?.title || t('common.yes') }}
        </ButtonBase>
        <ButtonBase v-bind="options?.noButton" @click="no">
          {{ options?.noButton?.title || t('common.no') }}
        </ButtonBase>
        <ButtonBase v-if="options?.anotherButton?.title" v-bind="options?.anotherButton" @click="another">
          {{ options?.anotherButton?.title }}
        </ButtonBase>
        <ButtonBase v-if="options?.hasCloseButton" variant="transparent" @click="closeContext">
          {{ t('common.close') }}
        </ButtonBase>
      </div>
    </LayoutBase>
  </PanePromise>
</template>

<script setup>
import { ref, watch } from 'vue';
import { LayoutBase } from '@/components/Layouts'
import { PanePromise, ButtonBase } from '@/UI'

import { useConfirm } from '@/composables/useConfirm';

const PanePromiseREF = ref()

const { isConfirmDialogOpen, handleResponse, options } = useConfirm();

const yes = async () => {
  console.log('yes')
  handleResponse(true);
  closeContext();
}

const no = () => {
  console.log('no')
  handleResponse(false);
  closeContext();
}

const another = () => {
  console.log('another')
  handleResponse('another');
  closeContext();
}

const openContext = async () => {
  await PanePromiseREF.value.open()
}

const closeContext = () => {
  PanePromiseREF.value.close()
  handleResponse('close');
}

watch(isConfirmDialogOpen, () => {
  if (isConfirmDialogOpen.value) {
    openContext();
  }
})

defineExpose({
  open: openContext
})
</script>

<style lang="scss" scoped>
.title {
    color: #6B7090;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.btns {
    display: grid;
    gap: 10px;
}
</style>
