<template>
  <BlockHeader
    :title="t('companyCreate.additionalStep.title')"
    :text="t('companyCreate.additionalStep.description')"
  />

  <div class="elements-wrapper">
    <TextareaBase
      v-model="companyInfo.about"
      :label="t('account.placeholders.about')"
    />
    <InputBase
      v-if="!isCompetitions"
      v-model="companyInfo.stag"
      :label="t('account.placeholders.stag')"
    />

    <ServiceSelector
      :key="companyInfo.categoryId"
      v-model:catprops="companyInfo.accountProps"
      v-model:types="companyInfo.account_type_id"
      v-model:fields="companyInfo.fields"
      :exec-type="companyInfo.executor_type"
      :category="companyInfo.categoryId"
    />

    <TextareaBase
      v-if="isShowMore"
      v-model="companyInfo.extraservice"
      :label="t('account.placeholders.extra')"
    />

    <PhotoGallery
      v-if="!isCompetitions"
      v-model="companyInfo.licensePhotos"
    >
      <template #button="{fn}">
        <div style="display: flex; justify-content: center; margin-top: 10px;">
          <ButtonBase variant="light" small width-auto @click="fn">
            {{ t('account.placeholders.licence') }}
          </ButtonBase>
        </div>
      </template>
    </PhotoGallery>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BlockHeader from '../components/BlockHeader.vue';

import { TextareaBase, InputBase, ButtonBase } from '@/UI';
import { ServiceSelector } from '@/components/ServiceSelector'
import { PhotoGallery } from '@/components/PhotoGallery'

const companyInfo = defineModel({ type: Object, default: () => {} })

const isCompetitions = computed(() => companyInfo.value.categoryId == 13)
const isShowMore = computed(() => {
  if (companyInfo.value.categoryId === 8 && companyInfo.value.executor_type === 1) {
    return false
  }
  return !isCompetitions.value
})

</script>

<style scoped lang="scss">
.elements-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
