<template>
  <BlockHeader
    :title="t('companyCreate.hoursStep.title')"
    :text="t('companyCreate.hoursStep.description')"
  />

  <div class="elements-wrapper">
    <InputCheckbox
      v-model="companyInfo.daynight"
      :title="t('account.placeholders.daynight')"
    />
    <template v-if="!companyInfo.daynight">
      <SelectFullPage
        id="dayoff"
        v-model="companyInfo.dayoff"
        fit-content
        is-multiple
        :label="t('account.placeholders.dayoff')"
        :items="daysOptions"
      />
      <SelectFullPage
        id="shortday"
        v-model="companyInfo.shortDay"
        fit-content
        is-multiple
        :label="t('account.placeholders.short')"
        :items="daysOptions"
      />
    </template>

    <template v-if="!companyInfo.daynight && isShowSheduleCalendar">
      <BlockText :text="t('account.time')" style="margin-top: 20px;" />
      <div class="line-wrapper">
        <TimePicker
          id="fdsf"
          v-model="companyInfo.timeFrom"
          :placeholder="t('account.placeholders.from')"
        />
        <TimePicker
          id="gfdb"
          v-model="companyInfo.timeTill"
          :placeholder="t('account.placeholders.till')"
        />
      </div>
      <InputCheckbox v-model="companyInfo.break" :title="t('account.placeholders.break')" />
      <div v-if="companyInfo.break" class="line-wrapper">
        <TimePicker
          id="fdgsf"
          v-model="companyInfo.breakFrom"
          :placeholder="t('account.placeholders.from')"
        />
        <TimePicker
          id="gfdhb"
          v-model="companyInfo.breakTill"
          :placeholder="t('account.placeholders.till')"
        />
      </div>

      <template v-if="companyInfo.shortDay?.length">
        <BlockText :text="t('account.shortTime')" style="margin-top: 20px;" />
        <div class="line-wrapper">
          <TimePicker
            id="bff"
            v-model="companyInfo.shortDaysTimeFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="brjh"
            v-model="companyInfo.shortDaysTimeTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
        <InputCheckbox v-model="companyInfo.breakShortDays" :title="t('account.placeholders.break')" />
        <div v-if="companyInfo.breakShortDays" class="line-wrapper">
          <TimePicker
            id="bdfdf"
            v-model="companyInfo.shortDaysBreakFrom"
            :placeholder="t('account.placeholders.from')"
          />
          <TimePicker
            id="bbrjhn"
            v-model="companyInfo.shortDaysBreakTill"
            :placeholder="t('account.placeholders.till')"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import BlockHeader from '../components/BlockHeader.vue';
import BlockText from '../components/BlockText.vue';
import { InputCheckbox, SelectFullPage } from '@/UI'
import TimePicker from '@/components/TimePicker'

import { daysOptions } from '../data';
import { computed } from 'vue';

const companyInfo = defineModel({ type: Object, default: () => {} })

const isShowSheduleCalendar = computed(() => {
  if (companyInfo.value.categoryId === 8 && companyInfo.value.executor_type === 1) {
    return false
  }
  if (companyInfo.value.categoryId === 13) {
    return false
  }
  return true
})
</script>

<style scoped lang="scss">
.elements-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.line-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
