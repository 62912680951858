<template>
  <CompanyTemplate
    v-model:step="currentStep"
    v-model:company-info="companyInfo"
    :is-show-shedule="isShowShedule"
    :common-errors="commonErrors"
    @back="onBack"
    @next="onNext"
    @create="onCompanyCreate"
    @final="onFinalStep"
  />
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'

import { CompanyTemplate, useCompanyValidation } from '@/screens/CompanyTemplate';

import { errorToast } from '@/helpers/showToast'
import { uploadPhoto } from '@/states/api'
import { createCompany } from './api';

import { useConfirm } from '@/composables/useConfirm'

const { t } = useI18n()

const currentStep = ref(1);

const companyInfo = reactive({
  name: '',
  logo: null,

  executor_type: 1,
  categoryId: 1,

  address: '',
  coords: [],

  animalTypes: [],

  email: '',
  tel: '',
  telegram: '',
  whatsApp: '',
  web: '',

  dayoff: [],
  shortDay: [],
  timeFrom: '',
  timeTill: '',
  shortDaysTimeFrom: '',
  shortDaysTimeTill: '',
  daynight: false,
  break: false,
  breakFrom: '',
  breakTill: '',
  breakShortDays: false,
  shortDaysBreakFrom: '',
  shortDaysBreakTill: '',

  about: '',
  extraservice: '',
  stag: '',
  licensePhotos: [],
  accountProps: [],
  account_type_id: [],
  fields: {},

  cost: '',

  isClinic: false,
  photos: [],
})

const {
  commonErrors,
  validateFirstStep,
  validateAddressStep,
  validateAnimalStep,
  validateContactStep,
} = useCompanyValidation({ companyInfo })

const router = useRouter();
const onBack = () => {
  if (currentStep.value === 1) {
    router.push('/companies');
    return;
  }

  if (currentStep.value > 1) {
    currentStep.value -= 1;
  }
}

const scrollToTop = () => {
  try {
    const PANE = document.querySelector('.cupertino.cupertino-fullpage')
    PANE.scrollTo({ top: 0 })
  } catch(e) {
    console.error(e);
  }
}

const processNextStep = (isSuccess = true) => {
  if (isSuccess) {
    currentStep.value += 1
  }
  scrollToTop();
}
const onNext = async () => {
  if (currentStep.value === 1) {
    validateFirstStep().then(processNextStep)
  } else if (currentStep.value === 2) {
    processNextStep()
  } else if (currentStep.value === 3) {
    validateAddressStep().then(processNextStep)
  } else if (currentStep.value === 4) {
    validateAnimalStep().then(processNextStep)
  } else if (currentStep.value === 5) {
    validateContactStep().then(processNextStep)
  } else {
    processNextStep()
  }
}

const isShowShedule = computed(() => {
  if (companyInfo.categoryId === 8 && companyInfo.executor_type === 1) {
    return false
  }
  if (companyInfo.categoryId === 13) {
    return false
  }
  return true
})

const addShowcase = async (id) => {
  const hasCloseButton = true;

  const { onConfirm } = useConfirm();
  const response = await onConfirm({
    title: t('account.addShowcase.title'),
    yesButton: { danger: false, title: t('account.addShowcase.addProduct') },
    noButton: { danger: false, title: t('account.addShowcase.addService') },
    anotherButton: { title: t('account.addShowcase.addPrice') },
    hasCloseButton,
  })

  if (response === 'another') {
    router.push(`/account-showcases/${id}/prices/create`)
    return;
  }

  if (response === 'close') {
    return;
  }

  if (response) {
    router.push(`/account-showcases/${id}/products/create`)
  } else {
    router.push(`/account-showcases/${id}/services/create`)
  }
}

const onUploadPhoto = async(field) => {
  const { paths } = await uploadPhoto({ photos: companyInfo[field] })
  if (paths) {
    companyInfo[field] = paths
  }
}

const isLoading = ref(false);
const createdCompanyId = ref(null);
const onCompanyCreate = async () => {
  isLoading.value = true

  const { paths } = await uploadPhoto({ photos: Array.from(companyInfo.logo) })
  companyInfo.logo = paths[0].path

  await onUploadPhoto('photos')
  await onUploadPhoto('licensePhotos')

  const { id, success } = await createCompany(companyInfo)

  if (success) {
    processNextStep()
    createdCompanyId.value = id
  } else {
    errorToast({ description: t('toast.somethingWrong') })
  }
}

const onFinalStep = () => {
  router.back()
  addShowcase(createdCompanyId.value);
  console.log(createdCompanyId.value);
  currentStep.value = 1;
}

</script>

