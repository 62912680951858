import { api } from "@/app/api"

export const editCompany = async (companyInfo) => {
  try {
    const { data } = await api.put('/v1/accounts', companyInfo )
    return data
  } catch (error) {
    console.error(error)
  }
}
